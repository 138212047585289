@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
  width: 100vw;
}
#content{
  overflow-x: hidden;
}
#hamburger span{
  transition: all 0.3s ease;
}
.hamburger-line{
  @apply rounded-xl w-[27px] h-[3px] block m-[5px];
}
#dark-toggle:checked ~ label div.toggle-circle {
  @apply translate-x-[18.5px];
}
.hamburger-active > span:nth-child(1){
  transform: rotate(-45deg) translate(-5px,6px);
}
.hamburger-active > span:nth-child(2){
  transition: all 0.7s ease;
  width:0;
}
.hamburger-active > span:nth-child(3){
  transform: rotate(45deg) translate(-5px,-6px);
}
@media screen and (max-width: 768px){
/*header{
  width: 100vw;
  background-color: #fafafa;
  position: fixed;
  z-index: 20;
}*/
#nav-links{
  position: fixed;
  z-index: 9;
  height: 100%;
  width: 100%;
  flex-direction: column;
  clip-path: circle(50px at 90% -20%);
  -webkit-clip-path: circle(50px at 90% -10%);
  transition: all 1s ease-out;
  pointer-events: none;
}
#nav-links.navOpen{
  clip-path: circle(1500px at 90% -10%);
  -webkit-clip-path: circle(1500px at 90% -10%);
  pointer-events: all;
}
#nav-links li:nth-child(1){
  transition: all 0.5s ease 0.2s;
}
#nav-links li:nth-child(2){
  transition: all 0.5s ease 0.4s;
}
#nav-links li:nth-child(3){
  transition: all 0.5s ease 0.6s;
}
#nav-links li:nth-child(4){
  transition: all 0.5s ease 0.7s;
}
#nav-links li:nth-child(5){
  transition: all 0.5s ease 0.8s;
}
#nav-li.fade{
  opacity: 1;
}
}
.border-social-media{
  @apply flex items-center h-[43px] w-[180px] justify-center rounded-lg hover:scale-[1.03] transition duration-300;
}
.icon-responsive{
  @apply max-[375px]:w-9 max-[425px]:w-9 max-[600px]:w-10;
}
.texttitle-responsive{
  @apply max-[375px]:text-2xl max-[425px]:text-[22px] max-[600px]:text-[26px] dark:text-[#E4E6EB];
}
.textparagraf-responsive{
  @apply max-[375px]:text-[15px] max-[425px]:text-[16px] max-[600px]:text-[17px];
}
.bg-border-mode{
  @apply dark:bg-[#262626] dark:border-[#171717];
}
@media screen and (max-width: 375px){
  .Text #frontend{
    color: transparent;
    -webkit-text-stroke: 1px #6b7280;
  }
  .Text #darkfrontend{
    color: transparent;
    -webkit-text-stroke: 1px #2dd4bf;
  }
}
@media screen and (min-width:375px) and (max-width: 768px){
  .Text #frontend{
    color: transparent;
    -webkit-text-stroke: 1.5px #6b7280;
  }
  .Text #darkfrontend{
    color: transparent;
    -webkit-text-stroke: 1.5px #2dd4bf;
  }
}
@media screen and (min-width:768px){
  .Text #frontend{
    color: transparent;
    -webkit-text-stroke: 2px #6b7280;
  }
  .Text #darkfrontend{
    color: transparent;
    -webkit-text-stroke: 2px #2dd4bf;
  }
}
.gradient-box{
  align-items: center;
  justify-content: center;
  display: flex;
}
.gradient-box div{
  margin: 0 0 200px 0;
  position: absolute;
  z-index: 1;
  width: 290px;
  height: 200px;
}
.blue__gradient{
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}
.red__gradient{
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #dc2626 100%);
  filter: blur(123px);
}
.Angles-Down{
  position: relative;
  animation: updown 2s ease-in-out infinite;
}
.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
}

.tooltip-container:hover .tooltip {
  top: -100%;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.rating:not(:checked) > input {
  position: absolute;
  appearance: none;
}

.rating:not(:checked) > label {
  float: right;
  cursor: pointer;
  font-size: 30px;
  color: #a3a3a3;
}

.rating:not(:checked) > label:before {
  content: '★';
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #facc15;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #facc15;
}

.rating > input:checked ~ label {
  color: #facc15;
}
.send svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}
.send:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.send:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}
.send:hover span {
  transform: translateX(5em);
}

.send:active {
  transform: scale(0.95);
}
.border-skill{
  @apply border-[1px] border-[#e5e5e5] dark:border-[#404040];
}
.bg-change{
  @apply dark:bg-[#262626] dark:border-[#404040];
}
.Skill .scroller__inner {
  display: flex;
  gap: 1rem;
  list-style: none;
}
.Skill .scroller__inner div {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.Skill .scroller__inner div img {
  padding: 0 10px 0 0;
  width: 35px;
}
.Skill .scroller__inner div span {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
}
.Skill .scroller[data-animated=true] {
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent);
          mask: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent);
}
.Skill .scroller[data-animated=true] .scroller__inner {
  width: -moz-max-content;
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 55s) var(--_animation-direction, forwards) linear infinite;
}
.Skill .scroller[data-speed=fast] {
  --_animation-duration: 20s;
}
.messages::-webkit-scrollbar {
  width: 5px;
}
.messages::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.messages::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}
.typing-effect .cursor {
  display: inline-block;
  margin-left: 2px;
  width: 10px;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to {
      color: transparent;
  }
  50% {
      color: black;
  }
}

@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.bounce-1 {

  animation: bounce 1s infinite;
  animation-delay: 0s;
}

.bounce-2 {
  animation: bounce 1s infinite;
  animation-delay: 0.1s;
}

.bounce-3 {
  animation: bounce 1s infinite;
  animation-delay: 0.2s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}
@keyframes updown {
  0% {
    top: 0;
  }
  50% {
    top: 3px;
  }
  100% {
    top: 0;
  }
}
.lamp{
  animation: expandHeight 6s ease-in-out infinite;
}
@keyframes expandHeight {
  0%, 100% {
    height: 2%;
  }
  50% {
    height: 5%;
  }
}
.plamp{
  animation: expandP 6s ease-in-out infinite;
}
@keyframes expandP {
  0%, 100% {
    height: 2%;
  }
  50% {
    height: 5%;
  }
}
.alamp{
  animation: expandA 6s ease-in-out infinite;
}
@keyframes expandA {
  0%, 100% {
    height: 5%;
  }
  50% {
    height: 14%;
  }
}
.clamp{
  animation: expandC 6s ease-in-out infinite;
}
@keyframes expandC {
  0%, 100% {
    height: 5%;
  }
  50% {
    height: 21%;
  }
}
::-webkit-scrollbar{
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb{
  border-radius: 100px;
  background: #a3a3a3;
}